import { isUserFrench, isUserInter } from '@/utils'

export const urlLogin = () => {    
    return "/login"
}

export const urlHomePage = () => {
    let path = "/";
    if(isUserFrench()){
        path = "/simple"
    }else if(isUserInter()){ 
        path = "/inter"
    }
    return path
}